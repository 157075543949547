import React from 'react';
import _ from 'lodash';
import Routes from './Routes';
import useBitrageActivated from 'hooks/useBitrageActivated';
import getLicenseType from 'components/Gunbot/LicenseLevel';

const RouteComponentWrapper = function ({ config }) {
  const bitRageActive = useBitrageActivated();
  const licenseType = getLicenseType(config);
  const isNotDemo = _.isNil(config.GUI?.demo) || config.GUI?.demo === false;
  const isExchangesEmpty = _.isEmpty(config.exchanges);
  const gunthy_wallet = config.bot.gunthy_wallet;

  return (
    <Routes
      bitRageActive={bitRageActive}
      licenseType={licenseType}
      isNotDemo={isNotDemo}
      isExchangesEmpty={isExchangesEmpty}
      gunthy_wallet={gunthy_wallet}
    />
  );
};

export default RouteComponentWrapper;